import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../../layout";
import { BlogEntry, DesktopRightImage, Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 className="px-2 text-center">Problems solved by Trailguide</h1>
    <h3 className="text-center text-lg">
  Some of the problems faced by guests and hosts in the tourism sector
    </h3>
    <br />
    <Image src="/invest/slitasje.jpg" mdxType="Image" />
    <h4>{`Finding nice tours`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  As a tourist or a guest arriving at a destination, you might want to go out
  and enjoy the outdoors. Maybe you want to go trail biking with friends, go
  hiking with your grandkids, or enjoy a nice mountain climb on your road bike.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  If you do not have fresh and updated tour information about the specific area,
  there is a great chance you will find tours that might be boring, outside of
  your comfort level, or just outright dangerous.
    </div>
    <br />
    <h4>{`Lack of updated information`}</h4>
    <Image src="/news/golsfjellet-kart.png" mdxType="Image" />
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The typical tour information you find in the tourist offices, hotels, B&B,
  sports shops, and campsites are printed maps of selected tours. The printed
  information is often bad, incomplete, outdated, and cannot be shared on the
  web.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The person standing behind the reception desk might not be familiar with the
  local area or have first-hand knowledge of the available tours.
    </div>
    <br />
    <h4>{`Missing knowledge about current conditions`}</h4>
    <Image src="/invest/mud.jpg" mdxType="Image" />
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  With outdated knowledge of tour conditions, you might send your guests out on
  a bad trip without knowing about it or hearing about it afterwards. Sending
  your guests into the mud with a promise of a nice tour does not make good
  outdoor experiences for them.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Your guests will be dissatisfied, maybe leave earlier than they planned, and
  worst of all, tell their friends not to visit your destination. Since they do
  not come back and tell you, you will not know about this. Your marketing
  budget is not being used well and you have a big problem.
    </div>
    <br />
    <h4>{`Consistent information across different media`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  If you are a tourism destination or business you want to share information
  across multiple types of media. You might want to have the same information in
  printed media you hand out, your website, other companies websites, or your
  own digital tour guide. If the data is not digitized and accessible from a
  central location, this will be a time consuming and costly struggle.
    </div>
    <br />
    <h4>{`Being a frustrated mountain biker`}</h4>
    <Image src="/invest/problem.png" mdxType="Image" />
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Without digital access to good quality and updated trail information for
  mountain biking, great trails are hard to find. You often end up in trouble
  just going after the trail markings on a map.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      